import React from 'react';

import FacebookButton from '../FacebookButton/FacebookButton';
import GoogleButton from '../GoogleButton/GoogleButton';
import TwitterButton from '../TwitterButton/TwitterButton';
import LinkedinButton from '../LinkedinButton/LinkedinButton';

import { useAuth0 } from '../../utils/auth0';

const SocialButtons = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <FacebookButton
        classList={{ root: 'col-12-xs' }}
        onClick={() => loginWithRedirect({ connection: 'facebook' })}
        data-testid="socialButtonFacebook"
      >
        Facebook
      </FacebookButton>
      <GoogleButton
        classList={{ root: 'col-12-xs' }}
        onClick={() => loginWithRedirect({ connection: 'google-oauth2' })}
        data-testid="socialButtonGoogle"
      >
        Google
      </GoogleButton>
      <TwitterButton
        classList={{ root: 'col-12-xs' }}
        onClick={() => loginWithRedirect({ connection: 'twitter' })}
        data-testid="socialButtonTwitter"
      >
        X (Twitter)
      </TwitterButton>
      <LinkedinButton
        classList={{ root: 'col-12-xs' }}
        onClick={() => loginWithRedirect({ connection: 'linkedin' })}
        data-testid="socialButtonLinkedin"
      >
        LinkedIn
      </LinkedinButton>
    </>
  );
};

SocialButtons.displayName = 'socialButtons';
export default SocialButtons;
